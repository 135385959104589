<template>
  <svg
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.01192 4.43057C9.32641 4.161 9.79989 4.19743 10.0695 4.51192L16.0695 11.5119C16.3102 11.7928 16.3102 12.2072 16.0695 12.4881L10.0695 19.4881C9.79989 19.8026 9.32641 19.839 9.01192 19.5695C8.69743 19.2999 8.661 18.8264 8.93057 18.5119L14.5122 12L8.93057 5.48811C8.661 5.17361 8.69743 4.70014 9.01192 4.43057Z"
      fill="currentColor"
    />
  </svg>
</template>
